.otpContainer {
  align-items: center;
  gap: 25px;
  margin-block-end: 10px;
}
.otpInput {
  border-radius: 5px;
  border: 0.966px solid #576378;
  width: 45px !important;
  height: 45px;
}
.otpLabel {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  color: #576378;
  width: 100%;
}
