.title {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  color: #838d9d;
  margin-bottom: 10px;
}

.label {
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  color: #838d9d;
  width: 100%;
}

.textInput {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background: #fafbff;
  border-radius: 3rem;
  border: 1px solid #838d9d;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #838d9d;
  margin-top: 8px;
}
.inputWrapper,
.selectWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkboxWrapper {
  margin-top: 15px;
  display: flex;
  gap: 5px;
}

.selectWrapper,
.textAreaWrapper {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
}

.selectWrapper .select {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #b3b2b2;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 24px;
  color: #667085;
  margin-top: 2px;
  position: relative;
  background: transparent;
  /* background-image: url("src/assets/svg/arrow-down.svg"); */
  background-repeat: no-repeat;
  background-position: right 2rem top 50%;
}

.textAreaWrapper .textArea {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 14px;
  gap: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 24px;
  color: #667085;
  margin: 5px 0;
  resize: none;
}

.textArea:focus,
.select:focus,
.textInput:focus {
  border-color: var(--primary-color) !important;
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.viewPwd {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 15px;
  color: #838d9d;
  cursor: pointer;
}

input:disabled,
textarea:disabled,
select:disabled {
  border-radius: 5px;
  border: 1px solid #b3b2b2;
  background: #ededed !important;
}
