@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-loader:disabled::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid;
    border-top-color: white;
    -webkit-animation: loader 0.8s linear infinite;
    animation: loader 0.8s linear infinite;
  }

  .btn-loader:disabled {
    position: relative;
    color: var(--primary-color) !important;
  }
  .btn-loader-red:disabled::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid;
    border-top-color: white;
    -webkit-animation: loader 0.8s linear infinite;
    animation: loader 0.8s linear infinite;
  }

  .btn-loader-red:disabled {
    position: relative;
    color: #f04444 !important;
  }
}

:root {
  --primary-color: #2e3a6e;
  --secondary-color: #576378;
  --gray: #7a88a0;
}

html {
  font-size: 62.5%;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 1.6rem;
  font-family: "inter";
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: var(--primary-color);
}

button:disabled {
  cursor: not-allowed;
}

.modal {
  margin-top: 50px;
  height: 800px;
}

.app-modal-content {
  border-radius: 10px;
  background: #fff;
  border: none;
  margin: 0 auto;
}

.modal-header {
  border-bottom: none;
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.6rem;
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ebebeb;
  padding: 15px 30px;
}

.modal-header::before,
.modal-header::after {
  display: none;
}

.modal-header h4.modal-title {
  font-size: 1.6rem;
  color: var(--primary-color);
  /* margin-bottom: 20px; */
}

.modal-header .close {
  padding: 10px;
  background-color: transparent;
  border: none;
  font-size: 2.4rem;
}

.rec-modal-body {
  padding: 30px;
}

.modal-xlg {
  width: 1200px !important;
  max-width: 1200px;
}

.modal-lg {
  width: 900px !important;
  max-width: 900px;
}

.modal-sm {
  width: 500px !important;
  max-width: 500px;
}
.modal-normal {
  width: 750px !important;
  max-width: 900px;
}

.modal-xsm {
  width: 400px !important;
  max-width: 400px;
}

.modal-xsm .modal-title {
  font-size: 0.5rem;
}

.modal-subtitle {
  margin-bottom: 30px;
  margin-top: -20px;
  font-size: 14px;
  font-family: inherit;
  color: #5e6c85;
}

.modal-dialog {
  max-width: 90vw;
  max-height: 100%;
}

.ReactModal__Overlay--after-open {
  z-index: 1000;
  background: rgba(0, 0, 0, 0.6) !important;
}

.ReactModal__Content--after-open {
  border: none !important;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 33px 5px rgb(22 49 96 / 19%);
  margin: 10px;
}

.popup-option {
  padding: 8px 15px;
  font-weight: 400;
  font-size: 1.4rem;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 5px;
  align-items: center;
}

.footer-container {
  background: url("src/assets/svg/footerbg1.svg");
}

.bg-image {
  background-image: url("src/assets/images/image.png");
  background-repeat: no-repeat;
  padding: 20px;
  background-position: center center;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  padding-inline: 0 !important;
}

.react-tabs__tab-list {
  background-color: white;
  padding-block: 1.2rem;
}

.react-tabs__tab {
  display: inline-block;
  position: relative;
  list-style: none;
  padding: 1rem 2rem;
  cursor: pointer;
  color: #576378;
  margin-right: 2px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.34rem;
}

.react-tabs__tab--selected {
  color: var(--primary-color);
  border-radius: 6px;
  font-weight: 600;
  letter-spacing: -0.18px;
  line-height: 2.34rem;
}

.react-tabs__tab:hover {
  background: var(--light-primary-color);
  color: var(--primary-color);
  border-radius: 6px;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:before {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
  border-bottom: 1px solid #cacbce;
}
.react-tabs__tab--selected:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
  border-bottom: 2px solid var(--primary-color);
}

.react-tabs__tab-panel {
  display: none;
  /* padding-inline: 2.5rem; */
}

.react-tabs__tab-panel--selected {
  display: block;
  margin-block: 2.5rem;
}

/*  */

.second-tabs__tab-list {
  margin-top: 5px;
}

.second-tabs__tab {
  display: inline-block;
  position: relative;
  list-style: none;
  padding: 10px 20px;
  cursor: pointer;
  color: #5e6c85;
  margin-right: 2px;
  font-size: 1.4rem;
  font-weight: 600;
}

.second-tabs__tab--selected {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.second-tabs__tab:hover {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.second-tabs__tab:focus {
  outline: none;
}

/*  */

.third-tabs {
  -webkit-tap-highlight-color: transparent;
  padding-inline: 0 !important;
}

.third-tabs__tab-list {
  background-color: white;
  padding-inline: 1.75rem;
  padding-block: 0.5rem;
  border-bottom: 1px solid var(--light-gray);
}

.third-tabs__tab {
  display: inline-block;
  position: relative;
  list-style: none;
  padding: 4px 6px;
  cursor: pointer;
  color: #5e6c85;
  margin-right: 2px;
  font-size: 1.25rem;
}

.third-tabs__tab--selected {
  background: var(--light-primary-color);
  color: var(--primary-color);
  border-radius: 6px;
}

.third-tabs__tab:hover {
  background: var(--light-primary-color);
  color: var(--primary-color);
  border-radius: 6px;
}

.third-tabs__tab:focus {
  outline: none;
}

.third-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.third-tabs__tab-panel {
  display: none;
}

.third-tabs__tab-panel--selected {
  display: block;
}

.swal2-popup.swal2-toast .swal2-title {
  font-size: 1.4rem !important;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media print {
  @page {
    size: 80mm 300mm;
  }
  .print {
    display: none;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 56.25%;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
}
